@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
/* add css module styles here (optional) */
.styles_recorder_library_box__3RTTT,
.styles_recorder_library_box__3RTTT * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif
}

.styles_recorder_library_box__3RTTT .styles_recorder_box__1sEIe {
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
}

.styles_recorder_library_box__3RTTT .styles_recorder_box_inner__CC5bV {
    min-height: 330px;
    background: transparent;
    border-radius: 0 0 3px 3px;
    display: flex;
    flex-direction: column;
}

.styles_recorder_library_box__3RTTT .styles_mic_icon__D9aTg {
    width: 60px;
    display: flex;
    height: 60px;
    position: fixed;
    justify-content: center;
    align-items: center;
    transition: .5s ease all;
    background: rgb(245, 0, 87);
    border-radius: 50%;
    bottom: 65px;
    right: 20%;
    color: #09141a;
    font-size: 25px;
}

.styles_recorder_library_box__3RTTT .styles_microphone_icon_sec__2GlfJ {
    width: 25px;
    height: 25px;
    display: flex;
}

.styles_recorder_library_box__3RTTT .styles_mic_icon__D9aTg:hover {
    background-color: #09141a;
}

.styles_recorder_library_box__3RTTT .styles_mic_icon_svg__3j9wM {
    fill: #09141a;
    width: 100%;
    height: 100%;
}

.styles_recorder_library_box__3RTTT .styles_mic_icon__D9aTg:hover .styles_mic_icon_svg__3j9wM {
    fill: rgb(245, 0, 87);
}

.styles_recorder_library_box__3RTTT .styles_reco_header__3wTWB {
    display: flex;
    justify-content: space-between;
    background: #bd9f61;
    align-items: center;
    padding: 20px 20px;
    color: #09141a;
    border-radius: 3px 3px 0 0;
}

.styles_recorder_library_box__3RTTT .styles_reco_header__3wTWB .styles_h2__1N622 {
    font-weight: 400;
}

.styles_recorder_library_box__3RTTT .styles_reco_header__3wTWB .styles_close_icons__1Cj_8 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease all;
}

.styles_recorder_library_box__3RTTT .styles_reco_header__3wTWB .styles_close_icons__1Cj_8:hover {
    background: rgba(123, 118, 106, 0.21);
}

.styles_recorder_library_box__3RTTT .styles_record_section__1yFRp {
    position: relative;
    flex: 1 1;
}

.styles_recorder_library_box__3RTTT .styles_record_section__1yFRp .styles_mic_icon__D9aTg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
}

.styles_recorder_library_box__3RTTT .styles_record_section__1yFRp .styles_duration_section__1O2AN {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 100px;
}

.styles_recorder_library_box__3RTTT .styles_btn_wrapper__1iQ3c {
    text-align: center;
    margin: 20px 30px;
}

.styles_recorder_library_box__3RTTT .styles_btn_wrapper__1iQ3c .styles_btn__3rAQU {
    border: 0;
    outline: 0;
    padding: 10px 20px;
    border-radius: 20px;
    background: #09141a;
    color: #fff;
    cursor: pointer;
    border: 1px solid #09141a;
    transition: .3s ease all;
}

.styles_recorder_library_box__3RTTT .styles_btn_wrapper__1iQ3c .styles_btn__3rAQU:hover {
    background: #fff;
    color: #09141a;
}

.styles_recorder_library_box__3RTTT .styles_btn_wrapper__1iQ3c .styles_clear_btn__2jYZx {
    background: #09141a;
    color: #fff;
    margin-left: 15px;
}

.styles_recorder_library_box__3RTTT .styles_btn_wrapper__1iQ3c .styles_clear_btn__2jYZx:hover {
    background: #fff;
    color: #09141a;
}

.styles_recorder_library_box__3RTTT .styles_duration__3qczc {
    text-align: center;
}

.styles_recorder_library_box__3RTTT .styles_recorder_page_box__1mrKm {
    min-height: calc(100vh - 128px);
    background: #09141a;
}

.styles_recorder_library_box__3RTTT .styles_duration__3qczc * {
    color: #09141a;
    font-size: 60px;
}

.styles_recorder_library_box__3RTTT .styles_duration_section__1O2AN .styles_help__3dRsD {
    color: #09141a;
    text-align: center;
}

.styles_recorder_library_box__3RTTT .styles_record_controller__3n_XG {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0px;
    padding: 20px 0;
    display: flex;
}

.styles_recorder_library_box__3RTTT .styles_record_controller__3n_XG .styles_icons__HVreQ {
    width: 50px;
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #09141a;
    margin-right: 15px;
    font-size: 20px;
}

.styles_recorder_library_box__3RTTT .styles_record_controller__3n_XG .styles_stop__1XNqK {
    background: #09141a;
    position: relative;
}

.styles_recorder_library_box__3RTTT .styles_record_controller__3n_XG .styles_pause__26okf {
    background: #09141a;
    position: relative;
}

.styles_recorder_library_box__3RTTT .styles_play_icons__3-eBe {
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 10px 0px 10px 17px;
    border-color: transparent transparent transparent #fff;
    box-sizing: border-box;
    position: absolute;
    left: 56%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.styles_recorder_library_box__3RTTT .styles_pause_icons__24htJ {
    width: 17px;
    height: 17px;
    border-style: double;
    border-width: 0px 0px 0px 15px;
    border-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.styles_recorder_library_box__3RTTT .styles_stop_icon__3uPIt {
    width: 17px;
    height: 17px;
    border: 1.5px solid #fff;
    border-radius: 2px;
}

.styles_recorder_library_box__3RTTT .styles_microphone_icon_sec__2GlfJ {
    width: 30px;
    height: 30px;
    display: flex;
}
